import React, { useState, useRef, useEffect } from 'react';
import { Container, Row, Col, Form, Button, Card, Navbar } from 'react-bootstrap';

import io from 'socket.io-client';

import { useParams, useSearchParams } from "react-router-dom";
import { useUserContext } from "../../contexts/UserContext";

import ToggleButton from 'react-bootstrap/ToggleButton';

import { FaRobot } from 'react-icons/fa';

import { Toast, ToastContainer } from 'react-bootstrap';

import "react-modern-calendar-datepicker/lib/DatePicker.css";
import { Calendar } from "react-modern-calendar-datepicker";

const Message = ({ text, sender }) => {
  return (
    <div
      style={{
        textAlign: sender === "user" ? 'right' : 'left',
        marginBottom: '10px',
      }}
    >
      <Card
        style={{
          display: 'inline-block',
          maxWidth: '70%',
          padding: '10px',
          borderRadius: '10px',
          backgroundColor: 'var(--bg-color)',
          color: sender === "user" ? '#fff' : '#000',
          wordWrap: 'break-word',
          fontSize: '18px',
        }}
        dangerouslySetInnerHTML={{ __html: text }}
      />
    </div>
  );
};

const HeaderSection = ({name, slug}) => {
  return (
    <Navbar
      bg="transparent"
      variant="dark" 
      className="custom-navbar"
      sticky="top" 
      style={{
        padding: '10px 20px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Container>
        <Navbar.Brand href={`https://salao.amarko.com.br/${slug}`} style={{
            fontSize: '24px', 
            fontWeight: 'bold', 
            color: '#fff', 
            display: 'flex', 
            alignItems: 'center',
            textAlign: 'center',
          }}>
          <FaRobot style={{ marginRight: '10px' }} /> ChatBot {name}
        </Navbar.Brand>
      </Container>
    </Navbar>
  );
};

function formatNumber(num) {
  return String(num).padStart(2, '0');
}

const currentDate = new Date();

const ChatBotPage = () => {

  const [searchParams] = useSearchParams();

  const phone = searchParams.get("phone");
  const whatsappCode = searchParams.get("whatsapp_code");

  const [currentProfessionalChecked, setCurrentProfessionalChecked] = useState(null);
  const [currentAvailabilityChecked, setCurrentAvailabilityChecked] = useState(null);
  const [allServicesChecked, setAllServicesChecked] = useState([]);

  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [clientId, setClientId] = useState('');
  const socketRef = useRef(null);

  const { setBusinessResponse, slugData, apiToken, validateApiToken, businessData, validateWhatsAppCode, validateCustomerToken } = useUserContext();
  const { slug } = useParams();

  const messagesEndRef = useRef(null);

  const [showToast, setShowToast] = useState(true);

  const [selectedDate, setSelectedDate] = useState({
    year: currentDate.getFullYear(),
    month: currentDate.getMonth() + 1,
    day: currentDate.getDate(),
  });


  useEffect(async () => {
    if(!apiToken){
      await validateApiToken(apiToken);
    }
    
    if(!slugData){
      setBusinessResponse(slug);
    }
  }, []);

  const footer = document.getElementsByClassName('footer');

  if(footer.length){
    footer[0].style.display = "none"
  }

  useEffect(async () => {
    if(businessData){

      let socketClientId;
      let customerDataObject = null;
      let currentCustomer = null;

      if(phone && whatsappCode){

        console.log(phone, whatsappCode, slug);
  
        const response = await validateWhatsAppCode(phone, whatsappCode, slug);
  
        console.log(response);
  
        if(response && response.data && response.data.token){
          console.log("setou token");
          localStorage.setItem("customerToken", response.data.token);
          currentCustomer = await validateCustomerToken(response.data.token);
        }else{
          if(localStorage.getItem("customerToken") && localStorage.getItem("customerToken") != "null"){
            currentCustomer = await validateCustomerToken(localStorage.getItem("customerToken"));
          }else{
            localStorage.setItem("customerToken", null);
          }
        }
      }else{
        if(localStorage.getItem("customerToken") && localStorage.getItem("customerToken") != "null"){
          currentCustomer = await validateCustomerToken(localStorage.getItem("customerToken"));
        }else{
          localStorage.setItem("customerToken", null);
        }
      }

      console.log("currentCustomer", currentCustomer);

      if(currentCustomer){
        setClientId(currentCustomer.id);
        socketClientId = currentCustomer.id;
        customerDataObject = currentCustomer;
      }else{
        const generatedClientId = `client-${Math.random().toString(36).substr(2, 9)}`;
        setClientId(generatedClientId);
        socketClientId = generatedClientId;
      }

      // Initialize socket connection only once
      if (!socketRef.current || socketRef.current.disconnected) {
        //socketRef.current = io('http://localhost:3000');
        socketRef.current = io('https://backend.amarko.com.br:3030');

        socketRef.current.emit('join', { storeId: businessData?.id, clientId: socketClientId, customerDataObject: customerDataObject, businessSlug: slugData });

        // Listen for messages from the server
        socketRef.current.on('message', (msg) => {
          setMessages((prevMessages) => [...prevMessages, msg]);
        });
      }

      const handleVisibilityChange = () => {       
        if (document.visibilityState === 'visible') {

          socketRef.current.disconnect();

          setMessages([]);

          socketRef.current = io('https://backend.amarko.com.br:3030');

          socketRef.current.emit('join', { storeId: businessData?.id, clientId: socketClientId, customerDataObject: customerDataObject, businessSlug: slugData });

          // Listen for messages from the server
          socketRef.current.on('message', (msg) => {
            setMessages((prevMessages) => [...prevMessages, msg]);
          });
        }
      };
  
      document.addEventListener('visibilitychange', handleVisibilityChange);
    }

    return () => {
      if (socketRef.current) {
      //  socketRef.current.off('message');
        socketRef.current.disconnect();
      }
      //document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [businessData]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (input.trim()) {
      const userMessage = { text: `<span style="color: var(--main-color-dark);"><b style="color: var(--main-color-ligth);">Você:</b> ${input}</span>`, sender: 'user' };
      setMessages((prevMessages) => [...prevMessages, userMessage]);
      socketRef.current.emit('message', {msg: input, businessSlug: slugData});
      setInput('');
    }
  };

  const handleSelectProfessional = (professional) => {
    setCurrentProfessionalChecked(professional);
    
    socketRef.current.emit('professional', { storeId: businessData?.id, clientId: clientId, professional: professional, businessSlug: slugData });
  }

  const handleSelectServices = () => {
    socketRef.current.emit('services', { storeId: businessData?.id, clientId: clientId, services: allServicesChecked, businessSlug: slugData });
  }

  const handleSelectAvailability = (hour) => {
    setCurrentAvailabilityChecked(hour);
    
    socketRef.current.emit('availability', { storeId: businessData?.id, clientId: clientId, hour: hour, businessSlug: slugData });
  }

  const handleChangeDate = (date) => {
    setSelectedDate(date);

    console.log(date);

    const formattedDate = `${formatNumber(date.day)}/${formatNumber(date.month)}/${date.year}`;

    const userMessage = { text: `<span style="color: var(--main-color-dark);"><b style="color: var(--main-color-ligth);">Você:</b> ${formattedDate} </span>`, sender: 'user' };
    setMessages((prevMessages) => [...prevMessages, userMessage]);
    socketRef.current.emit('message', {msg: formattedDate, businessSlug: slugData});
    setInput('');
  }

  const handleActionResumo = (actionType) => {
    socketRef.current.emit('action', { storeId: businessData?.id, clientId: clientId, action: actionType, businessSlug: slugData });
  };

  const numberFormat = (value) =>
    new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL'
  }).format(value);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);


  return (
    <Container
      fluid
      style={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        padding: 0,
        width: '100%',
        overflowX: 'hidden',
      }}
    >
     <HeaderSection name={businessData?.name} slug={slug} />

      <Row
        className="justify-content-center"
        style={{
          flexGrow: 1,
          overflowX: 'hidden',
          overflowY: 'auto',
          padding: '10px',
          marginBottom: '80px',
          height: 'calc(100vh - 70px - 80px)',
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: "var(--bg-color)",
        }}
      >
        <Col style={{overflowX: "hidden"}} xs={12}>
          <div style={{overflowX: "hidden"}}>
          {messages.map((msg, index) => (
              <>
                <Message className={msg.sender === 'user' ? 'user' : 'bot'} key={index} text={msg.text} sender={msg.sender} />

                {msg.classification == "informar_telefone" ? <li className={index === messages.length - 1 ? '' : 'disabled-element'} style={{ display: "flex", width: `${window.innerWidth}px`, position: "relative", overflowX: "auto", gap: '7.5px'}}> {msg.options.map((professional) => {
                  return <div style={{border: 'solid 1px', display: 'flex', flexDirection: 'column', alignItems: 'center'}} key={professional.id}>
                    
                    <div style={{width: '20vh', height: '20vh', borderBottom: 'solid 1px', alignContent: 'center', backgroundColor: 'white'}}> 
                      <img style={{width: '100%', height: '100%'}} src={professional.image?.url} alt='teste' /> 
                    </div>


                    <div style={{fontSize: '13px'}}> {professional.name} </div>

                    <ToggleButton style={{marginBottom: '7%', borderRadius: '0px', width: '75%', height: '23px', fontSize: '13px', alignItems: 'center', justifyContent: 'center', display: 'flex'}}
                    id={`professional-select-`+professional.id}
                    type="checkbox"
                    variant="outline-primary"
                    checked={currentProfessionalChecked?.id == professional.id ? true : false}
                    value={professional.id}
                    onChange={() => handleSelectProfessional(professional)}
                  >
                  Selecionar
                </ToggleButton>
                 </div>
                })} </li> : <></>}

                {msg.classification == "informar_data" ? <div className={index === messages.length - 1 ? '' : 'disabled-element'}> <div
                style={{ display: "flex", width: `${window.innerWidth}px`, position: "relative", overflowX: "auto", gap: '7.5px'}}> {msg.options.map((availability, index) => {
                  return <div style={{marginRight: "5px"}} key={index}><ToggleButton style={{borderRadius: '30px'}}
                    className="mb-2"
                    id={`availability-select-`+index}
                    type="checkbox"
                    variant="outline-primary"
                    checked={currentAvailabilityChecked == availability ? true : false}
                    value={availability}
                    onChange={() => handleSelectAvailability(availability)}
                  >
                  {availability}
                </ToggleButton>
                </div>
                })}
               
                </div> 
               <Button onClick={() => handleActionResumo("change_day")} style={{ width: '95%', marginLeft: '2.5%', borderRadius: '30px'}} variant="outline-primary">Alterar Data</Button>
               </div>: <></>}

                {msg.classification == "informar_servico" ? <li className={index === messages.length - 1 ? '' : 'disabled-element'} style={{ display: "flex", width: `${window.innerWidth}px`, position: "relative", overflowX: "auto", gap: '7.5px'}}> {msg.options.map((service, index) => {
                  return <div style={{border: 'solid 1px', display: 'flex', flexDirection: 'column', alignItems: 'center'}} key={index}>
                    
                  <div style={{width: '20vh', height: '20vh', borderBottom: 'solid 1px', alignContent: 'center', backgroundColor: 'white'}}> 
                    <img style={{width: '100%', height: '100%'}} src={service.image?.url} alt='teste' /> 
                  </div>

                  <div style={{fontSize: '13px'}}> {service.title} </div> 
                  
                  <div style={{width: '100%', display: 'flex', justifyContent: 'space-around'}}>
                  <span>{numberFormat(service.price)}</span>
                  <ToggleButton style={{marginBottom: '6%', borderRadius: '0px', width: '50%', height: '23px', fontSize: '13px', alignItems: 'center', justifyContent: 'center', display: 'flex'}}
  
                    id={`service-select-`+index}
                    type="checkbox"
                    variant="outline-primary"
                    checked={allServicesChecked.includes(service.id) ? true : false}
                    value={service.id}
                    onClick={() => {
                      if(allServicesChecked.includes(service.id)){
                        const newServices = allServicesChecked.filter((item) => item != service.id);
                        setAllServicesChecked(newServices);
                      } else {
                        setAllServicesChecked([...allServicesChecked, service.id]);
                      }
                    }}
                  >
                  Selecionar
                </ToggleButton>
                </div>
                </div>
                })} </li> : <></>}

                {msg.classification == "informar_servico" ? <div className={index === messages.length - 1 ? '' : 'disabled-element'} style={{width: '95%', marginLeft: '2.5%', gap: '10px', display: 'flex', flexDirection: 'column'}}> 
                  <Button onClick={() => handleActionResumo("change_professional")} style={{ width: '100%', borderRadius: '30px'}} variant="outline-secondary">Alterar Profissional</Button>
                  <Button className={allServicesChecked.length ? "" : "disabled-element"} style={{ width: '100%', borderRadius: '30px'}} onClick={handleSelectServices} variant="outline-primary">Avançar</Button> 
                  </div> : <></>}
                
                {msg.classification == "informar_dia" ? <div className={index === messages.length - 1 ? '' : 'disabled-element'} > <li style={{fontSize: "20px", margin: "5px"}} className={msg.sender === 'user' ? 'user' : 'bot'}>
                <Calendar
                  value={selectedDate}
                  onChange={(date) => handleChangeDate(date)}
                  shouldHighlightWeekends
                />
                </li> 
                <Button onClick={() => handleActionResumo("change_services")} style={{width: '95%', marginLeft: '2.5%', borderRadius: '30px'}} variant="outline-secondary">Alterar Serviços</Button>
                </div>: <></>}
                

                {msg.classification == "resumo" ? <div className={index === messages.length - 1 ? '' : 'disabled-element'} style={{ width: "95%", marginLeft: '2.5%', display: 'flex', flexDirection: 'column', gap: '10px'}}>     
                  <Button onClick={() => handleActionResumo("confirm")} style={{ width: '100%', borderRadius: '30px'}} variant="outline-primary">Confirmar Agendamento</Button>
                  <Button onClick={() => handleActionResumo("change_professional")} style={{ width: '100%', borderRadius: '30px'}} variant="outline-primary">Alterar Profissional</Button>
                  <Button onClick={() => handleActionResumo("change_services")} style={{width: '100%', borderRadius: '30px'}} variant="outline-primary">Alterar Serviços</Button>
                  <Button onClick={() => handleActionResumo("change_day")} style={{ width: '100%', borderRadius: '30px'}} variant="outline-primary">Alterar Data</Button>
                  <Button onClick={() => handleActionResumo("change_time")} style={{ width: '100%', borderRadius: '30px'}} variant="outline-primary">Alterar Horário</Button>
                  <Button onClick={() => handleActionResumo("cancel")} style={{ width: '100%', borderRadius: '30px', color: '#ff0000a3'}} variant="outline-primary">Cancelar Agendamento</Button>                                
                 </div> : <></>}
                 

                {msg.classification == "confirmar" ? <> 
                  <div style={{ position: 'relative' }}>
                    <ToastContainer position="top-end" className="p-3">
                      <Toast
                        show={showToast}
                        onClose={() => setShowToast(false)}
                        delay={10000}
                        autohide
                        bg={msg.options.alert_status}
                      >
                        <Toast.Header>
                          <strong className="me-auto">{msg.options.alert_title}</strong>
                          <small>Agora</small>
                        </Toast.Header>
                        <Toast.Body style={{color: "white"}}>
                          {msg.options.alert_message}
                        </Toast.Body>
                      </Toast>
                    </ToastContainer>

                  </div>
                </> : <></>}
              </>
            ))}
            <div ref={messagesEndRef} />
          </div>
        </Col>
      </Row>

      <Row
        className="justify-content-center"
        style={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 0,
          backgroundColor: '#fff',
          padding: '10px 0',
          zIndex: 10,
          backgroundColor: 'var(--bg-color)'
        }}
      >
        <Col xs={8}>
          <Form.Control
            type="text"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            placeholder="Digite sua mensagem..."
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleSubmit(e);
              }
            }}
            style={{height: '100%'}}
          />
        </Col>
        <Col xs={4}>
          <Button
            variant="dark"
            onClick={handleSubmit}
            style={{ width: '100%', height: '100%', color: "var(--main-color-dark)", backgroundColor: "var(--bg-color-dark)" }}
          >
            Enviar
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default ChatBotPage;
