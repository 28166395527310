import axios from "axios";

const BASE_URL = process.env.REACT_APP_URL_BACKEND;

function createConfig(apiToken) {
  return {
    headers: {
      Authorization: `Bearer ${apiToken}`,
    },
  };
}

async function createApiToken() {
  try {
    const promise = await axios.post(`${BASE_URL}/authorization/create`, {}, {
      headers: {
        Authorization: `TOKEN_SECREATO_123`,
      },
    });
    return promise.data;
  } catch (error) {
    return error.response;
  }
}

async function validateApiToken(apiToken) {
  try {
    const promise = await axios.post(`${BASE_URL}/authorization/validate`, {}, {
      headers: {
        Authorization: apiToken,
      },
    });
    return promise.data;
  } catch (error) {
    return error.response;
  }
}

async function validateCustomerToken(customerToken, apiToken) {
  try {
    const promise = await axios.post(`${BASE_URL}/authorization/customer/validate`, {token: customerToken}, createConfig(apiToken));
    return promise.data;
  } catch (error) {
    return error.response;
  }
}

async function getResponse(slug, apiToken) {
  try {
    const promise = await axios.get(`${BASE_URL}/${slug}`, createConfig(apiToken));
    return promise.data;
  } catch (error) {
    return error.response;
  }
}

async function signUp(formData, slug, apiToken) {
  try {
    const promise = await axios.post(`${BASE_URL}/${slug}/register`, formData, createConfig(apiToken));
    return promise;
  } catch (error) {
    return error.response;
  }
}

async function signIn(formData, slug, apiToken) {
  try {
    const promise = await axios.post(`${BASE_URL}/${slug}/login`, formData, createConfig(apiToken));
    return promise;
  } catch (error) {
    return error.response;
  }
}

async function checkAvailability(token, data, slug) {
  try {
    const promise = await axios.post(`${BASE_URL}/${slug}/check_availability`, data, createConfig(token));
    return promise;
  } catch (error) {
    return error.response;
  }
}

async function createCalendarEvent(token, data, slug) {
  try {
    const promise = await axios.post(`${BASE_URL}/${slug}/schedule`, data, createConfig(token));
    return promise;
  } catch (error) {
    return error.response;
  }
}

async function getReservations(token, slug, customerId) {
  try {
    const promise = await axios.get(`${BASE_URL}/${slug}/schedules?customerId=${customerId}`, createConfig(token));
    return promise;
  } catch (error) {
    return error.response;
  }
}

async function updateUser(token, slug, customerId, data, type) {
  try {
    const promise = await axios.put(`${BASE_URL}/${slug}/update_user?type=${type}&customerId=${customerId}`, data, createConfig(token));
    return promise;
  } catch (error) {
    return error.response;
  }
}

async function deleteReservation(token, slug, eventId, customerId) {
  try {
    const promise = await axios.delete(`${BASE_URL}/${slug}/schedule/${eventId}?customerId=${customerId}`, createConfig(token));
    return promise;
  } catch (error) {
    return error.response;
  }
}

async function validateWhatsAppCode(token, phone, code, slug) {
  try {
    const promise = await axios.post(`${BASE_URL}/${slug}/whatsapp/validate_code`, {phone, code}, createConfig(token));
    return promise;
  } catch (error) {
    return error.response;
  }
}








async function validateHash(hash) {
  try {
    const promise = await axios.post(`${BASE_URL}/hash-validation/${hash}`, {});
    return promise;
  } catch (error) {
    return error.response;
  }
}

async function sendRecuperationEmail(email) {
  try {
    const promise = await axios.post(
      `${BASE_URL}/generate-reset-url/${email}`,
      {}
    );
    return promise;
  } catch (error) {
    return error.response;
  }
}

async function resetPassword(hash, data) {
  try {
    const promise = await axios.post(
      `${BASE_URL}/reset-password/${hash}`,
      data
    );
    return promise;
  } catch (error) {
    return error.response;
  }
}

async function getSchedules() {
  try {
    const promise = await axios.get(`${BASE_URL}/schedules`);
    return promise;
  } catch (error) {
    return error.response;
  }
}

async function editSchedule(token, weekId, data) {
  try {
    const config = createConfig(token);
    const promise = await axios.post(
      `${BASE_URL}/schedule/${weekId}`,
      data,
      config
    );
    return promise;
  } catch (error) {
    return error.response;
  }
}

export {
  validateApiToken,
  createApiToken,
  validateCustomerToken,
  signUp,
  signIn,
  updateUser,
  checkAvailability,
  createCalendarEvent,
  getReservations,
  deleteReservation,
  validateHash,
  sendRecuperationEmail,
  resetPassword,
  getSchedules,
  editSchedule,
  getResponse,
  validateWhatsAppCode
};
