import { BrowserRouter, Routes, Route, Navigate, useParams } from "react-router-dom";
import HomePage from "./pages/HomePage";
import ServicesPage from "./pages/ServicesPage";
import AboutPage from "./pages/AboutPage";
import ContactUsPage from "./pages/ContactUsPage";
import ChatBotPage from "./pages/ChatBotPage";
import ProfileReservationsPage from "./pages/ProfileReservationsPage";
import ProfileConfigPage from "./pages/ProfileConfigPage";
import ResetPasswordPage from "./pages/ResetPasswordPage";
import AuthenticationModal from "./components/AuthenticationModal";
import UpdateNewUserModal from "./components/UpdateNewUserModal";
import NewFooter from "./components/Footer/NewFooter";
import NotFoundPage from "./pages/NotFoundPage";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import GlobalStyles from "./styles/GlobalStyles";
import { useEffect } from "react";
import { useUserContext } from "./contexts/UserContext";

function changeTitle(businessData) {
  document.title = "A'Marko | " + businessData?.name;
}

function setFaviconUrl(businessData) {
  const link = document.querySelector("link[rel*='icon']") || document.createElement('link');
  link.type = 'image/x-icon';
  link.rel = 'shortcut icon';
  link.href = businessData?.logo?.default?.url;
  document.getElementsByTagName('head')[0].appendChild(link);
}

function verificarManifestLink() {
  const manifestLink = document.querySelector('link[rel="manifest"]');
  if (manifestLink) {
    return true;
  } else {
    return false;
  }
}

function registerServiceWorker(slugData, businessData){
  if (slugData && businessData && !verificarManifestLink()) {
    const baseUrl = "https://salao.amarko.com.br/"+slugData;
    const manifestJson = generateManifestJson(baseUrl, businessData);

    // Crie um blob com o conteúdo do manifesto
    const blob = new Blob([manifestJson], { type: 'application/json' });
    const manifestUrl = URL.createObjectURL(blob);

    // Adicione o link do manifesto ao HTML
    const link = document.createElement('link');
    link.setAttribute('rel', 'manifest');
    link.setAttribute('href', manifestUrl);
    document.head.appendChild(link);
  }
}

const generateManifestJson = (baseUrl, businessData) => {
  const manifest = {
    "name": businessData?.name,
    "short_name": businessData?.name,
    "start_url": baseUrl,
    "display": "standalone",
    "icons": [
      {
        "src": businessData?.logo?.url,
        "sizes": "64x64 32x32 24x24 16x16",
        "type": "image/x-icon"
      }
    ]
  };

  // Converta o objeto em JSON
  return JSON.stringify(manifest);
};

export default function App() {
  const { apiToken, validateApiToken, businessData, slugData, personalizationData, userData, removeCustomerData } = useUserContext();
  const { slug } = useParams();

  useEffect(async () => {
    await validateApiToken(apiToken);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiToken]);

  useEffect(async () => {
    if (userData && businessData) {
      console.log('atualizado businessData:', businessData, userData);

      if(businessData?.id != userData?.business_establishment_id){
        console.log("vai remover cliente");
        localStorage.setItem("customerToken", null);
        localStorage.removeItem("customerToken");
        await removeCustomerData();
      }
    }
  }, [userData, businessData]);

  setFaviconUrl(businessData);
  changeTitle(businessData);
  registerServiceWorker(slugData, businessData);

  return (
    <>
      <BrowserRouter>
        <GlobalStyles bgColor={personalizationData?.salon_color} bgColorDark={personalizationData?.description_color} mainColorLigth={personalizationData?.less_dark_background_color} mainColorDark={personalizationData?.dark_background_color}/>
        <Routes>
          <Route path={":slug"} element={<HomePage />} />
          <Route path={":slug/servicos"} element={<ServicesPage />} />
          <Route path={":slug/sobre"} element={<AboutPage />} />
          <Route path={":slug/contato"} element={<ContactUsPage />} />
          <Route path={":slug/chat"} element={<ChatBotPage />} />
          <Route path={":slug/reservas"} element={<ProfileReservationsPage />} />
          <Route path={":slug/cadastro"} element={<ProfileConfigPage />} />
          <Route
            path={":slug/reset-password/:hash"}
            element={<ResetPasswordPage />}
          />
          <Route path={"/404"} element={<NotFoundPage />} />
          <Route path="*" element={<Navigate to="/404" replace />} />
        </Routes>
        <NewFooter />
      </BrowserRouter>
      <AuthenticationModal />
      <UpdateNewUserModal />
      <ToastContainer />
    </>
  );
}
